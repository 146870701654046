import Vue from 'vue'
import app from './app.vue'
import router from './router'
import store from './store'

import { userModule } from '@/store/modules/user-state'

import { firebase } from '@/firebase'
import vuetify from '@/plugins/vuetify'

import '@/plugins/google-analytics'
import '@/components/base'

let vueInstance: Vue

firebase.auth().onAuthStateChanged(async (user: any) => {
  await userModule.setUser(user)
  await userModule.getUserRoles()

  if (!vueInstance) {
    Vue.config.productionTip = false

    vueInstance = new Vue({
      router,
      store,
      vuetify,
      render: createElement => createElement(app)
    }).$mount('#app')
  }

  if (user != null && router.currentRoute.name === 'login') {
    let savedRoute: any = localStorage.getItem('route')

    if (savedRoute) {
      savedRoute = JSON.parse(savedRoute)

      router.push(savedRoute)
    } else {
      router.push('/')
    }
  }
})
