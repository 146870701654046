
import Vue from 'vue';
import { firebase } from '@/firebase';
import { mapGetters } from 'vuex';
import { eventBus } from '@/event-bus';
import { userModule, userHasRole } from '@/store/modules/user-state';

import { routes, adminRoutes } from '@/router/menu-routes';

export default Vue.extend({
  components: {},
  data() {
    return {
      routes,
      adminRoutes,
    };
  },
  computed: {
    user(): any {
      return userModule.user;
    },
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    async signOut() {
      await firebase.auth().signOut();

      this.$router.push('/login');
    },

    userHasRole(roles: string[]) {
      return userHasRole(roles);
    },

    toggleSideNav() {
      eventBus.$emit('toggle-side-nav');
    },
  },
});
