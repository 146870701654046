
import Vue from 'vue';

import AppBar from '@/components/app-bar.vue';
import SideNav from '@/components/side-nav.vue';

import '@/assets/styles/site.css';

export default Vue.extend({
  name: 'app',
  components: {
    AppBar,
    SideNav
  },
  computed: {}
});
