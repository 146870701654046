
import Vue from 'vue';
import Component from 'vue-class-component';
import { db, firebase } from '@/firebase';

import { guildApplicationModule } from '@/store/modules/guild-application-state';

@Component({})
export default class GuildApplication extends Vue {
  private players: number = 20;
  private ac: number = 18;

  public runSimulation() {
    const results: any[] = [];

    for (let i = 0; i < 1000; i++) {
      const roundResults: any[] = [];

      for (let i = 0; i < this.players; i++) {
        roundResults.push(this.rollAttack(this.ac));
      }

      results.push(this.sumArray(roundResults));
    }

    console.log(`AC {ac} | 1000 iterations results.`);
    console.log('====================================');
    console.log(results);
    console.log();
    console.log('Average damage per round ' + this.averageResult(results));
  }

  private rollRandomNumber(modifier: number, minimum?: number, maximum?: number, advantage?: boolean) {
    let result = Math.floor(Math.random() * Math.floor(maximum || 20));

    if (minimum && result < minimum) {
      result = this.rollRandomNumber(modifier, minimum, maximum);
    }

    if (advantage) {
      const advantageResult = this.rollRandomNumber(modifier, minimum, maximum);

      if (advantageResult > result) {
        result = advantageResult;
      }
    }

    if (result == 20) {
      result = (result + modifier) * 2;
    }
    
    return result + modifier;
  }

  public sumArray(resultArray: any[]) {
    let total = 0;

    for (let i = 0; i < resultArray.length; i++) {
      total += resultArray[i];
    }

    return total;
  }

  public averageResult(resultArray: any[]) {
    return this.sumArray(resultArray) / resultArray.length;
  }

  private rollAttack(ac: number) {
    let attackResult = this.rollRandomNumber(5, 1, 20, true) - ac;

    if (attackResult < 0) {
      attackResult = 0;
    }

    return attackResult;
  }
}
