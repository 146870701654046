import { render, staticRenderFns } from "./about.vue?vue&type=template&id=7b393108"
import script from "./about.vue?vue&type=script&lang=ts"
export * from "./about.vue?vue&type=script&lang=ts"
import style0 from "./about.vue?vue&type=style&index=0&id=7b393108&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VParallax } from 'vuetify/lib/components/VParallax';
installComponents(component, {VCard,VCardText,VCardTitle,VContainer,VFlex,VLayout,VParallax})
