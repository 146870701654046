
import Vue from 'vue';
import Component from 'vue-class-component';

import { d20AbilitiesModule } from '@/store/modules/d20-abilities-state';

@Component({})
export default class GuildApplication extends Vue {
  private get loading() {
    return d20AbilitiesModule.loading;
  }

  private get abilities() {
    return d20AbilitiesModule.abilities;
  }

  public created() {
    d20AbilitiesModule.loadD20Abilities();

    this.abilities[0].title
  }
}
