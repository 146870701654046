
import Vue from 'vue';
import { db, firebase } from '@/firebase';

export default Vue.extend({
  data: function() {
    return {
      step: 0,
      applicationSubmitted: false,
      submittingApplication: false,
      forms: {
        valid: true,
        characterInfo: {
          form: {
            name: '',
            race: '',
            class: '',
            backstory: '',
          },
        },

        history: {
          valid: true,
          form: {
            previousGuilds: '',
            explainPowerModding: '',
            expectedFromGuild: '',
          },
        },

        exampleEmotes: {
          valid: true,
          form: {
            exampleEmote: '',
          },
        },
      },
      rules: {
        required: [(value: string) => !!value || 'This field is required.'],
      },
    };
  },
  created() {
    this.applicationSubmitted = JSON.parse(localStorage.getItem('applicationSubmitted') || 'false');
  },
  methods: {
    async nextStep() {
      if (this.step === 1 && (this.$refs.characterInfoForm as any).validate()) {
        this.step = 2;
      } else if (this.step === 2 && (this.$refs.historyForm as any).validate()) {
        this.step = 3;
      } else if (this.step === 3 && (this.$refs.exampleEmotesForm as any).validate()) {
        await this.submitForm();
      }
    },

    async previousStep() {
      if (this.step === 3) {
        this.step = 2;
      } else if (this.step === 2) {
        this.step = 1;
      }
    },

    async submitForm() {
      this.submittingApplication = true;

      const application = {
        characterInfo: { ...this.forms.characterInfo.form },
        history: { ...this.forms.history.form },
        exampleEmotes: { ...this.forms.exampleEmotes.form },
      };

      try {
        await db
          .collection('applications')
          .doc(application.characterInfo.name)
          .set(application);

        this.applicationSubmitted = true;
        localStorage.setItem('applicationSubmitted', 'true');
      } catch (error) {
        console.error(error);
        alert('Something went wrong submitting your application, please try again.');
      }

      this.submittingApplication = false;
    },
  },
});
