
import Vue from 'vue';

export default Vue.extend({
  computed: {
    isXL(): boolean {
      return this.$vuetify.breakpoint.xl;
    },
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
});
