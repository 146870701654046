
import Vue from 'vue';
import { db } from '@/firebase';

import { Resource } from '@/models/resource';

export default Vue.extend({
  data: () => ({
    resources: [] as Resource[]
  }),
  async mounted() {
    await this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const result = await db
        .collection('resources')
        .orderBy('views', 'desc')
        .limit(4)
        .get()
        .then(querySnapshot => {
          const resources: Resource[] = [];

          querySnapshot.forEach(document => {
            const id = document.id;
            const data: any = document.data();

            resources.push({
              id: document.id,
              ...data
            });
          });

          this.resources = resources;
        });
    }
  },
  computed: {
    isXL(): boolean {
      return this.$vuetify.breakpoint.xl;
    }
  }
});
