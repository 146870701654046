
import Vue from 'vue';

const divisions = [
  {
    name: 'S.U.N.',
    motto: 'Motto: "Building a brighter future, together."',
    description:
      'S.U.N. (Science United Network) Scientists of all walks of life, be it specializing in ' +
      'explosives, firearms, siege weaponry, robotics, alchemy or otherwise, we gather together for ' +
      'the sole purpose of innovation. We are the Research and Development wing of Duskfall. Though ' +
      'our resources are limited, our minds are not.'
  },
  {
    name: 'Dusk Vanguard',
    description:
      'Up-front and direct, the Vanguard leads the charge and spearheads most of the military ' +
      'actions of the Collective. Facing threats directly, we are the vanguard against the darkness, ' +
      'against the evils of the world. Most members of the Vanguard are the more physical fighters ' +
      'of the Collective - but any who have a fire in their hearts, and the courage to spare are welcome.'
  },
  {
    name: 'Magisters',
    description:
      'The Magisters is a group made up of some of the brightest minds Azeroth has to offer. They are ' +
      `Duskfall's top authority on all things pertaining to magic, and control a vast reservoir of ` +
      'knowledge on various subjects. The vaults that are maintained by the Magisters of Duskfall are ' +
      'rumored to hold many powerful artifacts.'
  },
  {
    name: 'Nightstalkers',
    description:
      'The Nightstalkers, a group made up of the less renown members of society, are the Duskfall ' +
      `Collective's hidden dagger. Accepting of most races and creeds, these individuals are ` +
      'expected to gather intelligence discreetly and gracefully, regardless of their target(s) ' +
      'being friend or foe.'
  }
];

const leaders = [
  {
    name: 'Raphah Sunflame',
    description:
      'As an elf of low birth, Raphah Sunflame clawed his way through life to the pinnacle of arcane ' +
      'knowledge, eventually joining the Silver Covenant. Nowadays, he assists The Magisters, and' +
      ' helps manage and contain threats not only to himself, but to his people.'
  },
  {
    name: 'Malithanore Vanyali',
    description:
      'Once the scout-lieutenant of the Highguard, Malithanore now serves as the Spymaster for the ' +
      'Duskfall Collective -- specifically, the Nightstalker division. His charisma, jovial ' +
      'attitude, and love for the arcane may dissuade others from believing so at a glance, however.'
  },
  {
    name: 'Seriphene Vanyali',
    description:
      `Serving as Duskfall's Chief Ambassador, Seri is responsible for the day to day politics and ` +
      'public relations. In addition to having a history in politics, she is also an accomplished ' +
      'mage, and leads The Magisters, making her a double threat, and a valuable asset.'
  },
  {
    name: 'Lyraeni Sorrowsong',
    description:
      'A Ranger-Captain in life, Lyra has served under the scourge, and again under the Ebon Blade. ' +
      'Now master of her own fate, Lyra leads the Duskfall Collective to fight against the darkness ' +
      'of the world. Strong and fiercly loyal to her friends, Lyra will stop at nothing to continue ' +
      'her fight.'
  },
  {
    name: 'Miralyne Valetender',
    description:
      `Miralyne Valetender is the Master Engineer of the Highguard's MagiTech Division, and she now ` +
      'performs a similar role within the Duskfall Collective. As leader of S.U.N., she aims to ' +
      'bring about a brighter future through the power of magic and technology.'
  }
];

export default Vue.extend({
  data() {
    return {
      divisions,
      leaders
    };
  }
});
