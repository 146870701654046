import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

if (!firebase.apps.length) {
  const config = {
    apiKey: 'AIzaSyA0q_o10spEDRhfOQQcVIwsSlI63R_pNhw',
    authDomain: 'the-duskfall-collective.firebaseapp.com',
    databaseURL: 'https://the-duskfall-collective.firebaseio.com',
    projectId: 'the-duskfall-collective',
    storageBucket: 'the-duskfall-collective.appspot.com',
    messagingSenderId: '100109515851',
    appId: '1:100109515851:web:6b51965ce4b0c602'
  };

  firebase.initializeApp(config);
}

const auth = firebase.auth();
const db = firebase.firestore();

export { auth, db, firebase };
