import Vue from 'vue';
import Router from 'vue-router';
import { auth } from '@/firebase';

import { authGuard } from './route-guards/auth-guard';

import home from '@/views/home.vue';
import about from '@/views/about.vue';
import join from '@/views/join.vue';
import d20 from '@/views/d20/d20.vue';
import d20Simplified from '@/views/d20/d20-simplified.vue';
import d20abilities from '@/views/d20/d20abilities.vue';
import resources from '@/views/resources.vue';
import resource from '@/views/resource.vue';
import login from '@/views/auth/login.vue';
import d20Test from '@/views/test-results.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
      path: '/about',
      name: 'about',
      component: about
    },
    {
      path: '/d20',
      name: 'd-20',
      component: d20
    },
    {
      path: '/d20-simplified',
      name: 'd-20-simplified',
      component: d20Simplified
    },
    {
      path: '/d20abilities',
      name: 'd20abilities',
      component: d20abilities
    },
    {
      path: '/resources',
      name: 'resources',
      component: resources
    },
    {
      path: '/resources/:resourceId',
      name: 'resource',
      component: resource
    },
    {
      path: '/join',
      name: 'join',
      component: join
    },

    {
      path: '/d20-test',
      name: 'd20-test',
      component: d20Test
    },

    {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        requiresNoAuth: true
      }
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: async (to, __, next) => {
        await auth.signOut();

        next('/');
      }
    },

    {
      path: '/applications',
      name: 'applications',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/applications.vue'),
      meta: {
        allowedRoles: ['leadership']
      }
    },
    {
      path: '/applications/:applicationName',
      name: 'application',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/application.vue'),
      meta: {
        allowedRoles: ['leadership']
      }
    }
  ]
});

router.beforeEach(authGuard);

export default router;
