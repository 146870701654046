
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    lorem: `Lorem ipsum dolor sit amet, mel at clita quando. Te sit oratio vituperatoribus, nam ad ipsum posidonium mediocritatem, explicari dissentiunt cu mea. Repudiare disputationi vim in, mollis iriure nec cu, alienum argumentum ius ad. Pri eu justo aeque torquatos.`,
  }),
  computed: {
    isXL(): boolean {
      return this.$vuetify.breakpoint.xl;
    },
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
