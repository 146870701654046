import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { firebase, auth, db } from '@/firebase';
import { User } from 'firebase';

import { store } from '@/store';

export interface D20Ability {
  title: string;
  apCost: number;
  type: string;
  description: string;
}

export interface D20AbilitiesState {
  loading: boolean;
  abilities: D20Ability[];
  currentAbility: D20Ability | null;
}

@Module({ dynamic: true, store, name: 'D20Abilities' })
class D20AbilitiesModule extends VuexModule implements D20AbilitiesState {
  public loading: boolean = false;

  public currentAbility: D20Ability | null = null;
  public abilities: D20Ability[] = [];

  @Action
  async loadD20Ability(abilityId: string) {
    this.mutateAbility(null);
    this.mutateLoading(true);

    try {
      const ability = await db
        .collection('d20-abilities')
        .doc(abilityId)
        .get()
        .then(t => t.data());

      this.mutateAbility(ability);
    } catch (error) {
      console.error(error);
      alert('Something went wrong retrieving abilities, please try again.');
    }

    this.mutateLoading(false);
  }

  @Action
  async loadD20Abilities() {
    this.mutateAbilities([]);
    this.mutateLoading(true);

    try {
      const abilities = await db
        .collection('d20-abilities')
        .get()
        .then(t => t.docs)
        .then(t => t.map(o => o.data()));

      this.mutateAbilities(abilities);
    } catch (error) {
      console.error(error);
      alert('Something went wrong submitting your application, please try again.');
    }

    this.mutateLoading(false);
  }

  @Action
  async addD20Ability(ability: D20Ability) {
    this.mutateLoading(true);

    try {
      await db.collection('d20-abilities').add(ability);
    } catch (error) {
      console.error(error);
      alert('Something went wrong retrieving abilities, please try again.');
    }

    this.mutateLoading(false);
  }

  @Action
  async updateD20Ability(abilityId: string, ability: D20Ability) {
    this.mutateLoading(true);

    try {
      await await db
        .collection('d20-abilities')
        .doc(abilityId)
        .set(ability);
    } catch (error) {
      console.error(error);
      alert('Something went wrong retrieving abilities, please try again.');
    }

    db.collection('cities').add({
      name: 'Tokyo',
      country: 'Japan',
    });

    this.mutateLoading(false);
  }

  @Mutation
  private mutateLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @Mutation
  private mutateAbility(currentAbility: any) {
    this.currentAbility = currentAbility;
  }

  @Mutation
  private mutateAbilities(abilities: any[]) {
    this.abilities = abilities;
  }
}

export const d20AbilitiesModule = getModule(D20AbilitiesModule);
