import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { firebase, auth, db } from '@/firebase';
import { User } from 'firebase';

import { store } from '@/store';

export interface UserState {
  user: User | null;
  roles: string[] | null;
}

const providers = () => ({
  google: new firebase.auth.GoogleAuthProvider(),
});

@Module({ dynamic: true, store, name: 'User' })
class UserModule extends VuexModule implements UserState {
  public user: null | User = null;
  public roles: string[] | null = null;

  @Action
  async signIn(provider) {
    if (this.user) {
      throw new Error('User already logged in.');
    }

    await auth.signInWithPopup(providers()[provider]);
  }

  @Action
  async setUser(user) {
    if (user) {
      this.mutateUser(user);
    } else {
      this.mutateUser(null);
    }
  }

  @Action
  async getUserRoles() {
    const roles: string[] = [];

    if (this.user) {
      const result = await db
        .collection('users')
        .doc(this.user.uid)
        .get()
        .then(t => t.data());

      if (result && Array.isArray(result.roles)) {
        roles.push(...result.roles);
      }
    }

    this.mutateRoles(roles);
  }

  @Mutation
  private mutateRoles(userRoles: string[]) {
    this.roles = [...userRoles];
  }

  @Mutation
  private mutateUser(user: null | User) {
    this.user = user ? { ...user } : null;
  }
}

export const userModule = getModule(UserModule);

export function userHasRole(roles: string | string[]) {
  if (typeof roles === 'string') {
    roles = [roles];
  }

  if (Array.isArray(roles)) {
    const result = (userModule.roles || []).some(userRole => roles.includes(userRole));

    return result;
  }

  return false;
}
