
import Vue from 'vue';
import { firebase } from '@/firebase';
import { mapGetters } from 'vuex';
import { eventBus } from '@/event-bus';
import { routes, adminRoutes } from '@/router/menu-routes';
import { userModule, userHasRole } from '@/store/modules/user-state';

export default Vue.extend({
  components: {},
  data() {
    return {
      drawer: false,
      adminRoutes,
      routes
    };
  },
  mounted() {
    eventBus.$on('toggle-side-nav', () => {
      this.drawer = !this.drawer;
    });
  },
  computed: {
    user(): any {
      return userModule.user;
    },
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    userHasRole(roles: string[]) {
      return userHasRole(roles);
    },

    async signOut() {
      await firebase.auth().signOut();

      this.$router.push('/login');
    }
  }
});
