import Vue from 'vue';
import { NavigationGuard } from 'vue-router';
import { db, firebase } from '@/firebase';
import { userModule, userHasRole } from '@/store/modules/user-state';

export const authGuard: NavigationGuard<Vue> = async (to, __, next) => {
  if (userModule.roles == null && userModule.user) {
    await userModule.getUserRoles();
  }

  const allowedRoles = to.meta.allowedRoles;
  const requiresNoAuth = to.meta.requiresNoAuth;
  const isAuthorized = await userHasRole(allowedRoles);

  if (allowedRoles && userModule.user == null) {
    return next('/login');
  }

  if (allowedRoles && !isAuthorized) {
    return next('/');
  }

  if (requiresNoAuth && isAuthorized) {
    return next('/');
  }

  next();
};
