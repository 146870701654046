
import Vue from 'vue';
import { db } from '@/firebase';

import { Resource } from '@/models/resource';
import { mapGetters } from 'vuex';
import { userModule, userHasRole } from '@/store/modules/user-state';

export default Vue.extend({
  data: () => ({
    resources: [] as Resource[],
  }),
  async mounted() {
    await this.getDataFromApi();
  },
  methods: {
    userHasRole(roles: string[]) {
      return userHasRole(roles);
    },

    async getDataFromApi() {
      const result = await db
        .collection('resources')
        .orderBy('title')
        .get()
        .then(querySnapshot => {
          const resources: Resource[] = [];

          querySnapshot.forEach(document => {
            const id = document.id;
            const data: any = document.data();

            resources.push({
              id: document.id,
              ...data,
            });
          });

          this.resources = resources;
        });
    },
  },
  computed: {
    isXL(): boolean {
      return this.$vuetify.breakpoint.xl;
    },
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
