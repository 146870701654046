export const adminRoutes = [
  {
    title: 'Applications',
    route: '/applications'
  },
  {
    title: 'Sign Out',
    route: '/logout'
  }
];

export const routes = [
  // {
  //   title: 'About',
  //   route: '/about'
  // },
  {
    title: 'D20',
    route: '/d20'
  },
  {
    title: 'Resources',
    route: '/resources'
  },
  // {
  //   title: 'Gallery',
  //   route: '/gallery'
  // },
  {
    title: 'Join',
    route: '/join'
  }
];
