
import Vue from 'vue';
import { mapActions } from 'vuex';

import { firebase, auth } from '@/firebase';

export default Vue.extend({
  methods: {
    ...mapActions(['signIn'])
  }
});
