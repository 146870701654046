import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import '@/assets/styles/vuetify.styl';
// import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#589EF7',
        secondary: '#D8D8D8',
        accent: '#D8D8D8',
        error: '#ff3636',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#000'
      },
      dark: {
        primary: '#589EF7',
        secondary: '#D8D8D8',
        accent: '#D8D8D8',
        error: '#ff3636',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#fff'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'md'
  }
});
