import Vue from 'vue';
import Vuex from 'vuex';

import { UserState } from './modules/user-state';
import { GuildApplicationState } from './modules/guild-application-state';

Vue.use(Vuex);

export interface RootState {
  users: UserState;
  guildApplications: GuildApplicationState;
}

export const store = new Vuex.Store<RootState>({

});

export default store;
