
import Vue from 'vue';
import VueMarkdown from 'vue-markdown';
import { db } from '../firebase';
import { mapGetters } from 'vuex';
import { userModule, userHasRole } from '@/store/modules/user-state';

export default Vue.extend({
  components: {
    VueMarkdown,
  },
  data() {
    return {
      resource: null as any,
      editing: false,
      preview: false,
      loading: false,
      savingChanges: false,
    };
  },
  async mounted() {
    if (this.$route.params.resourceId === 'new') {
      this.resource = {
        title: 'New Resource',
        content: '# New Resource',
      };

      this.editing = true;

      return;
    }

    await this.getDataFromApi();
    await db
      .collection('resources')
      .doc(this.$route.params.resourceId)
      .update({ views: this.resource.views + 1 });

    if (this.resource.url) {
      if (this.resource.url && this.resource.url[0] === '/') {
        this.$router.push(this.resource.url);
      } else {
        window.location = this.resource.url;
      }
    }
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;

      this.resource = await db
        .collection('resources')
        .doc(this.$route.params.resourceId)
        .get()
        .then(t => t.data());

      this.loading = false;
    },
    async saveChanges() {
      try {
        this.savingChanges = true;

        if (this.$route.params.resourceId === 'new') {
          db.collection('resources')
            .doc(this.resource.title.toLowerCase().replace(' ', '-'))
            .set({
              views: 0,
              title: this.resource.title,
              content: this.resource.content,
            });
        } else {
          db.collection('resources')
            .doc(this.$route.params.resourceId)
            .update({
              title: this.resource.title,
              content: this.resource.content,
            });
        }

        this.editing = false;
        this.preview = false;
      } catch (err) {
        throw err;
      } finally {
        this.savingChanges = false;
      }
    },
    userHasRole(roles: string[]) {
      return userHasRole(roles);
    },
    async cancel() {
      const confirmation = confirm('Are you sure you wish to cancel?\r\n\r\nYou will lose all of your progress.');

      if (confirmation) {
        await this.getDataFromApi();

        this.editing = false;
        this.preview = false;
      }
    },
  },
  computed: {
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
